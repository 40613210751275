@import "theme/ui-components/_variables";
@import "theme/ui-components/_mixins";

$row-height: size("mobile-menu-row-height");
$item-padding: size("gutter");
$desktop-border: color("border--muted");
$mobile-border: color("border");
$desktop-margin: size("gutter");
$line-height: 1.4;

.drawer {
  overflow-y: scroll;
  overflow-x: hidden;
  overscroll-behavior: contain;
  max-height: calc(100vh - (#{size("header-height")} * 2));

  @media (max-width: breakpoint("medium") - 1px) {
    max-height: calc(var(--windowInnerHeight, 100vh) - #{size("header-height")});
  }
}

.row {
  width: 100vw;
}

.wrapper {
  display: flex;

  @media (min-width: breakpoint("tiny-small")) and (max-width: breakpoint("medium") - 1px) {
    flex-direction: column;
    align-items: flex-start;
    padding-top: size("gutter--mini");
    padding-bottom: size("gutter--mini");

    &:not(:first-child) {
      border-top: 1px solid $desktop-border;
    }

    &:last-child {
      border-bottom: 1px solid $desktop-border;
    }
  }

  @media (min-width: breakpoint("medium")) {
    padding-top: $desktop-margin;
    padding-bottom: $desktop-margin;

    &:not(:first-child) {
      border-top: 1px solid $desktop-border;
    }

    &:last-child {
      border-bottom: 1px solid $desktop-border;
    }
  }

  @media (max-width: breakpoint("tiny-small") - 1px) {
    padding: 0 !important;
    flex-direction: column;

    &:not(:first-child) {
      border-top: 1px solid $mobile-border;
    }

    &:last-child {
      border-bottom: 1px solid $mobile-border;
    }
  }
}

.items {
  line-height: $line-height;

  @media (min-width: breakpoint("tiny-small")) and (max-width: breakpoint("medium") - 1px) {
    max-height: none !important;
    width: calc(100% + #{size("gutter") * 2}) !important;
    margin-left: -#{size("gutter")};
    margin-right: -#{size("gutter")};

    & > div {
      display: flex;
      flex-wrap: wrap;
    }
  }

  @media (min-width: breakpoint("medium")) {
    padding-top: 0.3rem;
    max-height: none !important;
    width: 80% !important;

    & > div {
      display: flex;
      flex-wrap: wrap;
    }
  }
}

.item {
  color: color("text--muted");
  font-size: size("font--small");

  @media (max-width: breakpoint("tiny-small") - 1px) {
    display: block;
    background: #f4f4f3;
    color: color("text");
    border-top: 1px solid $mobile-border;
    padding: 0 size("gutter");
  }

  @media (min-width: breakpoint("tiny-small")) {
    width: 25%;
    padding: size("gutter");
  }

  @media (min-width: breakpoint("medium")) {
    width: 20%;
  }

  &__heading {
    @media (max-width: breakpoint("tiny-small") - 1px) {
      height: $row-height;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 size("gutter");
    }
  }
}

.heading {
  font-size: size("font--medium");
  color: color("text");
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  line-height: $line-height;
  width: 20%;
  padding: size("gutter") size("gutter") size("gutter") 0;

  @media (min-width: breakpoint("tiny-small")) and (max-width: breakpoint("medium") - 1px) {
    width: auto;
  }

  @media (max-width: breakpoint("tiny-small") - 1px) {
    height: $row-height;
    align-items: center;
    flex-grow: 1;
    padding-left: size("gutter");
  }

  &__large {
    @media (max-width: breakpoint("tiny-small") - 1px) {
      display: none;
    }
  }

  &__small {
    @media (min-width: breakpoint("tiny-small")) {
      display: none;
    }
  }
}

.actionButton {
  padding: 0;
  height: $row-height;
  width: $row-height;
  justify-content: flex-end !important;
}

.chevron {
  transition: transform transition("duration--atoms") transition("ease-in");
  width: 36px;
  height: 36px;

  @media (min-width: breakpoint("tiny-small")) {
    display: none;
  }

  &.chevronOpen {
    transform: rotate(180deg);
  }
}

.parent {
  @media (max-width: breakpoint("tiny-small") - 1px) {
    .heading {
      font-size: size("font--small");
    }
  }

  &__item {
    display: flex;

    @media (max-width: breakpoint("tiny-small") - 1px) {
      padding: 0 size("gutter");
    }

    @media (min-width: breakpoint("tiny-small")) {
      display: none;
    }
  }
}
