@import "theme/ui-components/_variables";

$view-transition-ease: transition("ease-ease");
$view-transition-duration: transition("duration--slow");

.notices {
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: z-index("popup") + 1;
  box-shadow: 0 -6px 36px 0 rgba(0, 0, 0, 0.12);

  > div:not(:first-child) {
    border-top: 1px solid color("border--muted");
  }
}

.notificationBar {
  background-color: color("background--light");
  color: color("primary");
  border: none;
}

:global {
  .leave {
    width: 100%;
    opacity: 1;
    position: absolute;
  }

  .leave * {
    animation: none !important;
    opacity: 1 !important;
  }

  .leaving {
    opacity: 0;
    transition: $view-transition-duration opacity $view-transition-ease;
  }

  .enter {
    opacity: 0;
  }

  .entering {
    opacity: 1;
    transition: $view-transition-duration opacity $view-transition-ease;
  }
}

:export {
  viewduration: $view-transition-duration;
}

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
}

.mainContent {
  flex: 1 0 auto;
  padding-bottom: size("gutter--large") * 3;
  position: relative;
}

/* Footer styling */

.footer {
  flex: 0 0 auto;
}

.termsField {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  gap: size("grid-gutter");

  @media (max-width: breakpoint("small")) {
    flex-direction: column;
    align-items: flex-start;
  }
}
