@use "sass:math";

@import "../../node_modules/@crossroads/ui-components/src/ui-components/ProductCard";
@import "theme/ui-components/_variables";

.discount {
  background: #3f921b;
  text-align: center;
  line-height: 1;
  font-weight: 700;
  font-size: 0.9rem;
  position: absolute;
  top: 1.13rem;
  left: 0;
  padding: 0.565rem;
  height: auto;
  width: auto;
  border-radius: 0;
}
