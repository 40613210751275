@use "sass:math";
@import "theme/ui-components/_variables";
@import "theme/ui-components/_mixins";

:export {
  mini: strip-unit(breakpoint("mini"));
  small: strip-unit(breakpoint("small"));
  medium: strip-unit(breakpoint("medium"));
  large: strip-unit(breakpoint("large"));
}

$top-space: 35px;
$top-space--mobile: 25px;

.home {
  // negate app footer margin and apply here instead to keep background color
  padding-bottom: #{size("gutter--large") * 1.5};
}

.logos .box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 0 21%;
  margin: math.div(size("gutter"), 2);
  min-width: 100px;
  height: 150px;
  border-radius: 3px;
  border: solid 1px color("border");

  @media (max-width: breakpoint("tiny")) {
    flex: 1 0 33%;
    height: 100px;
  }

  @media (max-width: breakpoint("tiny")) {
    height: 80px;
  }
}

.popularBrands,
.popularCategories,
.popularProducts {
  margin-top: size("gutter") * 2.6;
  content-visibility: auto;

  .link {
    padding: size("gutter") 0 size("gutter-large");
  }

  .box {
    transition:
      transform transition("duration--atoms") transition("ease-in"),
      box-shadow transition("duration--atoms") transition("ease-in");

    &:hover {
      box-shadow: 0 8px 15px 0 rgba(0, 0, 0, 0.1);
      transform: translateY(-3px);
    }
  }

  @media (max-width: breakpoint("tiny")) {
    margin-top: size("gutter") * 2;
  }
}

.sectionHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: size("gutter--large");

  .sectionTitle {
    margin-bottom: 0;
    font-size: size("font--huge");
    color: color("primary");
  }
}

.popularProducts {
  .products {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + 1.13rem);
    margin: - math.div(size("gutter"), 2);

    @media (max-width: breakpoint("medium")) {
      justify-content: center;
    }

    .product {
      flex: 1 0 25%;
      width: 25%;
      padding: math.div(size("gutter"), 2);

      @media (max-width: breakpoint("medium")) {
        flex: 1 0 33.33%;
        width: 33.33%;
      }

      @media (max-width: breakpoint("tiny")) {
        flex: 1 0 100%;
      }
    }
  }

  .cta {
    display: block;
    margin: size("gutter") 0 size("gutter");
    flex: 0 1 auto;
  }
}

.popularCategories {
  @media (max-width: breakpoint("tiny")) {
    padding: 0 0 size("gutter");
  }
}

.buttonWrapper {
  text-align: center;
  margin-top: size("gutter");
}

.carouselWrapper {
  user-select: none;
  width: calc(100% + 16.08px);
  margin-left: -8.04px;

  @media (max-width: breakpoint("tiny")) {
    overflow: hidden;
  }
}

.logos {
  display: flex;
  flex-wrap: wrap;

  img {
    height: 100px;

    @media (max-width: breakpoint("tiny")) {
      height: 50px;
    }
  }
}

.desktop {
  @media (max-width: breakpoint("tiny")) {
    display: none;
  }
}

.popularBrandsButton {
  margin: size("gutter") 0 size("gutter");
  flex: 0 1 auto;
}

.double {
  display: flex;
  flex: 0 1 auto;

  > a {
    width: 50%;
  }

  a:first-of-type {
    margin-right: size("gutter");
  }

  @media (max-width: breakpoint("tiny")) {
    display: inherit;
    flex: inherit;
    margin: 0;

    > a {
      width: inherit;
    }

    a:first-of-type {
      margin-right: inherit;
    }
  }
}

.single,
.double {
  margin-top: size("gutter") * 3;

  @media (max-width: breakpoint("tiny")) {
    margin-top: 0;
  }
}

.carouselItem {
  background-color: #fff;
  margin: 0 math.div(size("gutter--small"), 2) size("gutter");
  border: 1px solid color("border--muted");
  border-radius: size("border-radius");
  overflow: hidden;
  cursor: pointer;
  transition: all transition("duration--molecules") transition("ease-ease");

  .image {
    display: block;
    margin: 0 auto;
    width: 100%;

    img {
      width: 100%;
    }
  }

  @media (max-width: breakpoint("tiny")) {
    margin: 0 math.div(size("gutter--small"), 2) size("gutter");
  }

  @media (min-width: breakpoint("tiny")) {
    &:hover {
      box-shadow: 0 4px 8px 0 rgba(213, 212, 209, 1);
      text-decoration: underline;
      color: color("text");
      opacity: 0.8;
    }
  }

  .imageWrapper {
    position: relative;
  }

  &:hover {
    border-color: color("text--muted");

    &:active {
      opacity: inherit;
    }

    .image {
      transform: scale(1.01);
    }
  }

  .titleWrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    @media (max-width: breakpoint("tiny")) {
      font-size: size("font--small");
      top: math.div(-$top-space--mobile, 2);
    }
  }

  .title {
    text-align: center;
    color: color("text");
    margin: 0;
    padding: size("gutter") 0;

    @media (max-width: breakpoint("tiny")) {
      font-size: size("font--small");
    }
  }
}

.carouselControls {
  display: flex;
  justify-content: center;
  align-items: center;
}
