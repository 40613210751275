@import "../../node_modules/@crossroads/shop-views/src/shop-views/Checkout";
@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

.checkout {
  margin-top: size("gutter--large") * 2;

  @media (max-width: breakpoint("small")) {
    margin-top: size("gutter--large");
  }

  &StepTitle {
    margin-top: size("gutter--mini") !important;
  }
}
