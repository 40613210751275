@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";
@import "../../node_modules/@crossroads/ui-components/src/ui-components/AppFooter";

.footer {
  background: color("primary");
  color: color("text--inverted");

  .heading {
    color: color("text--inverted");
  }
}

.nav,
.col nav {
  a,
  .link {
    color: color("text--inverted");
  }
}

.category a {
  color: color("text--inverted");
}

.bottomField {
  border-top: 1px solid color("primary--light");
}

.contentTerms {
  * {
    color: color("text--inverted");
    font-size: size("font--small");
  }

  a,
  button,
  button > span {
    color: color("text--inverted");
  }
}

.cols {
  display: flex;
  flex-wrap: wrap;

  & > div {
    margin-top: size("gutter--large");

    &:nth-child(1) {
      flex: 1 0 23%;
    }

    &:nth-child(2) {
      flex: 1 0 43%;
    }

    &:nth-child(3) {
      flex: 1 0 33%;
    }

    @media (max-width: (breakpoint("small") - 1px)) {
      &:nth-child(1) {
        flex: 1 0 50%;
      }

      &:nth-child(2) {
        flex: 1 0 50%;
      }

      &:nth-child(3) {
        flex: 1 0 100%;
      }
    }

    @media (max-width: (breakpoint("tiny") - 1px)) {
      &:nth-child(1) {
        flex: 1 0 100%;
      }

      &:nth-child(2) {
        flex: 1 0 100%;
        order: 3;
      }

      &:nth-child(3) {
        flex: 1 0 100%;
        order: 2;
      }
    }
  }
}
