@use "sass:math";

@import "theme/ui-components/_variables";
@import "theme/ui-components/_mixins";

$imageWidthIncrease: 10px;

:export {
  tiny: strip-unit(breakpoint("tiny"));
  small: strip-unit(breakpoint("small"));
  medium: strip-unit(breakpoint("medium"));
  large: strip-unit(breakpoint("large"));
}

.hoverFG {
  z-index: 1;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
  pointer-events: none;
  opacity: 0;
  transition: opacity transition("duration--molecules") transition("ease-ease");
}

.header {
  overflow: hidden;
  background: color("disabled");
}

.imageWrapper {
  transition: transform transition("duration--molecules") transition("ease-ease");
  position: relative;
}

.image {
  position: absolute;
  width: 100%;
}

.body {
  background: #fff;
  padding: size("gutter");
  border-top: 1px solid color("border--muted");
  text-align: left;
  height: 145px;
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
}

.saleTag {
  color: #fff;
  background: color("success");
  text-align: center;
  line-height: 1;
  font-weight: 700;
  font-size: 0.9rem;
  position: absolute;
  top: size("gutter");
  left: 0;
  padding: 0.565rem;
}

.brand {
  color: color("text--muted");
  margin: 0 0 size("grid-gutter") !important;

  @media (max-width: breakpoint("tiny")) {
    font-size: size("font--small");
  }
}

.top {
  width: 100%;
}

.name {
  color: color("primary");
  font-size: size("font");
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
  line-height: font("line-height");
  width: 100%;
  margin-bottom: math.div(size("gutter"), 4);

  @media (max-width: breakpoint("tiny")) {
    font-size: size("font--small");
  }
}

.brand,
.price {
  line-height: 1;
}

.price {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  font-size: size("font--small");
  color: color("text--muted");
  margin-bottom: 0;

  &__value > span {
    display: block;
  }

  span:first-child {
    margin-top: math.div(size("gutter"), 2);
  }

  @media (max-width: breakpoint("tiny")) {
    font-size: size("font--mini");
  }
}

.msrp {
  text-decoration: line-through;
  margin-top: size("gutter--tiny");
}

.dummy {
  .image {
    position: relative;
    display: block;
    width: 100%;
    max-width: 100%;
    background-image: linear-gradient(90deg, color("disabled") 0%, color("background") 50%, color("disabled") 100%);
    background-size: 200%;
    animation: shine 1.5s infinite linear;
  }

  span,
  p {
    display: block;
    background-image: linear-gradient(90deg, color("disabled") 0%, color("background") 50%, color("disabled") 100%);
    background-size: 200%;
    animation: shine 1.5s infinite linear;
  }

  .name {
    max-width: 50%;
  }

  .brand {
    max-width: 53%;
  }

  .price {
    max-width: 42%;
  }
}

.productCard {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #fff;
  overflow: hidden;
  position: relative;
  text-decoration: none;
  transition:
    border-color transition("duration--molecules") transition("ease-ease"),
    boxh-shadow transition("duration--molecules") transition("ease-ease");
  border: 1px solid color("border--muted");
  border-radius: size("border-radius");

  &:not(.dummy):hover {
    text-decoration: none;
    border-color: color("text--muted");
    box-shadow: 0 4px 8px 0 rgba(213, 212, 209, 1);

    .hoverFG {
      opacity: 0.2;
    }

    .imageWrapper {
      transform: scale(1.01);
    }

    .name {
      text-decoration: underline;
    }
  }
}

@keyframes shine {
  0% {
    background-position: -100%;
  }

  100% {
    background-position: 100%;
  }
}

.colors {
  display: flex;
  justify-content: center;
  position: absolute;
  top: size("gutter--mini");
  z-index: 1;
  left: 0;
  right: 0;

  & > div {
    width: 14px;
    height: 14px;
    border-radius: 14px;
    margin: 0 math.div(size("gutter--mini"), 2);
  }
}
