@use "sass:math";

@mixin row($padding: math.div($gutter, 2)) {
  @include clearfix;
  @include stretch;
  display: flex;
  flex-wrap: wrap;
  padding: 0 $padding;
}

@mixin stretch($distance: math.div(size("grid-gutter"), 2)) {
  left: -#{$distance};
  width: calc(100% + #{$distance * 2});
  max-width: none;

  :global(.no-csscalc) & {
    left: auto;
  }
}

@mixin clearfix {
  &::after {
    content: "";
    clear: both;
    display: table;
  }
}

@mixin resetInput {
  border: none;
  outline: none !important;
  background: none;
  box-shadow: none;
  appearance: none;
  line-height: 1;
  border-radius: 0;
  padding: 0;
  margin: 0;
  cursor: text;
  color: inherit;
  touch-action: manipulation;
  -webkit-tap-highlight-color: rgba(#fff, 0);

  &:disabled {
    cursor: not-allowed;
  }
}

%resetInput {
  @include resetInput();
}

@mixin vertical-margins($margin) {
  margin-top: $margin;
  margin-bottom: $margin;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

@mixin placeholderStyles {
  &::-webkit-input-placeholder {
    @content;
  }

  &::placeholder {
    @content;
  }

  &:input-placeholder {
    @content;
  }
}
