@import "../../node_modules/@crossroads/ui-components/src/ui-components/Tooltip";

.block {
  &:focus {
    outline: none;
  }
}

.tooltip,
.tooltopInner {
  line-height: font("line-height");

  &:focus {
    outline: none;
  }
}
