@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

$card-height: 60px;
$input-height: 60px;
$input-width: 380px;
$default-card-border-radius: 12px;
$box-shadow-card: 0 2px 4px 1px rgba(0, 0, 0, 0.3);
$border: 1px solid color("border--muted");

:export {
  tiny: strip-unit(breakpoint("tiny"));
}

/* General Styling */

.default,
.plain {
  &Inner {
    position: relative;
    width: 100%;

    &Front {
      &Pin {
        margin-top: size("gutter--small");
        display: none;
      }
    }

    &Back {
      display: none;

      .close {
        position: absolute;
        top: size("gutter");
        right: size("gutter");
        z-index: 1;
        cursor: pointer;
      }
    }
  }

  .input {
    height: 3rem;
    line-height: 3rem;
    font-size: 0.875rem !important; // 14px
    cursor: text;
  }

  .buttonCode,
  .buttonPin {
    flex: 1 0 auto;
    font-size: 0.875rem !important; // 14px

    svg {
      transform: rotate(180deg);
      width: 20px;
      height: 20px;
      opacity: 0.8;
    }
  }
}

/* Default Variant */

.default {
  @media (max-width: (breakpoint("tiny") - 1px)) {
    display: flex;
    flex-direction: column;
  }

  @media (min-width: breakpoint("tiny")) {
    max-width: $input-width;
    perspective: 1000px;
  }

  &Inner {
    .innerHeading {
      display: none;
    }

    &Flipped {
      @media (min-width: breakpoint("tiny")) {
        transform: rotateY(180deg);
      }
    }

    @media (min-width: breakpoint("tiny")) {
      height: $input-height;
      transition: transform transition("duration--templates") transition("ease-ease");
      transform-style: preserve-3d;
    }

    &Front,
    &Back {
      @media (min-width: breakpoint("tiny")) {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        backface-visibility: hidden;
        background: color("primary");
        color: #fff;
        border-radius: $default-card-border-radius;
        box-shadow: $box-shadow-card;
      }
    }

    &Front {
      @media (min-width: breakpoint("tiny")) {
        background: color("primary");
      }

      &Pin {
        @media (max-width: (breakpoint("tiny") - 1px)) {
          &.requiresPin {
            display: block;
          }
        }
      }
    }

    &Back {
      @media (min-width: breakpoint("tiny")) {
        background: color("secondary");
      }

      @media (min-width: breakpoint("tiny")) {
        display: block;
        transform: translateZ(-1px) rotateY(180deg);
      }
    }
  }

  .label {
    @media (min-width: breakpoint("tiny")) {
      display: none !important;
    }
  }

  .input {
    @media (min-width: breakpoint("tiny")) {
      background: none;
      border: none;
      color: #fff;
      height: $input-height;
      width: 100%;
      font-size: size("font") !important;
      padding: 0 size("gutter--large");
      padding-right: size("gutter--large") * 2.8;
    }

    &::placeholder {
      color: #fff;
      opacity: 0.6;
    }
  }

  .buttonWrapperCode,
  .buttonWrapperPin {
    @media (min-width: breakpoint("tiny")) {
      position: absolute;
      top: 0;
      right: size("gutter");
      bottom: 0;
      display: flex;
      align-items: center;
    }
  }

  .buttonCode,
  .buttonPin {
    @media (min-width: breakpoint("tiny")) {
      box-shadow: none;
      background: none !important;
      padding: 0;

      & > span {
        display: none;
      }
    }

    @media (max-width: (breakpoint("tiny") - 1px)) {
      width: 100%;
      margin-top: size("gutter");

      svg {
        display: none;
      }
    }
  }
}

/* Plain Variant */

.plain {
  display: flex;
  flex-direction: column;

  &Inner {
    border: $border;
    border-radius: size("border-radius");

    .innerHeading {
      padding: size("gutter") size("gutter") size("grid-gutter");
      font-weight: 600;
    }

    &Front,
    &Back {
      background: color("body");
      color: color("text");
      border-radius: 4px;
      padding: size("gutter--small") size("gutter") size("gutter");
    }

    &Front {
      display: flex;
      justify-content: space-between;
      gap: size("gutter");
      align-items: center;

      & > div:first-child {
        width: 100%;
      }

      &ActivePin {
        & > div:first-child {
          width: 0%;
          display: none;
        }
      }

      &Pin {
        margin-top: 0;

        &.requiresPin {
          display: block;
        }
      }

      .buttonWrapperCode,
      .buttonWrapperPin {
        width: 40%;
      }
    }
  }

  .buttonCode,
  .buttonPin {
    height: 3rem;
    width: 100%;

    svg {
      display: none;
    }
  }

  .label {
    color: color("primary");
    border-bottom: $border;
    padding-bottom: size("gutter--mini");
    margin-bottom: size("gutter");
    display: none;
  }
}
