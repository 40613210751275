@use "sass:math";

@import "theme/ui-components/_variables";
@import "theme/ui-components/_mixins";

$icon-size: 36px;

.searchMenu {
  width: 100%;
}

.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: color("OOC--color");
  height: size("header-height");


  @media (min-width: breakpoint("medium")) {
    display: none;
  }
}

.heading {
  display: block;
  color: color("secondary");
  font-size: size("font--medium");
  font-weight: bold;
}

.subHeader {
  color: color("text");
  font-size: size("font--medium");
}

.close,
.submit {
  @include resetInput;
  cursor: pointer;
}

.container {
  display: flex;
  align-items: center;
  background: #fff;
  border-radius: 4px;
  padding: 0 size("gutter");

  @media (max-width: breakpoint("medium") - 1px) {
    margin: size("gutter--small") !important;
    border: 1px solid color("border");
  }
}

.icon {
  width: $icon-size;
  height: $icon-size;
}

.image {
  border-radius: size("border-radius");
  margin-right: size("gutter");
}

.searchIcon {
  flex: 0 1 auto;
  margin-right: size("gutter");
}

.closeIcon {
  width: 36px;
  height: 36px;
  margin-left: size("gutter");

  @media (max-width: breakpoint("tiny")) {
    margin-left: size("gutter--tiny");
  }
}

.inputWrapper {
  width: 100%;
  padding: math.div(size("grid-gutter"), 2) 0;
}

.input {
  width: 100%;
  padding: size("gutter--small");
  border: none;
  border-left: 1px solid color("border");
  font-size: size("font") !important;
  background: none;
  cursor: text;

  @include placeholderStyles {
    color: color("text--muted");
  }

  &:focus {
    outline: none;
  }

  @media (min-width: breakpoint("medium")) {
    border-right: 1px solid color("border");
  }
}

.suggestionsSection {
  background: color("body");
  position: absolute;
  width: 100%;
  margin-top: 1px;
  border-radius: 4px;
  overflow: hidden;
  font-size: size("font");
  z-index: z-index("overlay");
}

.searchQuery {
  background: color("body--off");
  padding: 0 size("gutter--small");

  &Link {
    display: block;
    padding: size("gutter--small") 0;
    color: color("text");
  }
}

.suggestions {
  margin: 0 size("gutter--small");
}

.suggestion {
  &:not(:first-child) {
    border-top: 1px solid color("border");
  }

  &Link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: size("gutter--small") 0;
    color: color("text");
  }

  &Left,
  &Right {
    display: flex;
    align-items: center;
  }

  &Body {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .price {
    margin-top: size("gutter--mini");
  }

  span {
    color: color("text--muted");
  }
}
