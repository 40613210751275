@import "theme/ui-components/_variables";

.block {
  flex: 1 0 auto;
  display: flex;

  input {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 6;
    font-size: 32px;
    text-align: center;
    background: transparent;
    border: none;
    cursor: text;
  }
}

.box {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  height: 50px;
  border: 1px solid color("border");
  border-radius: size("border-radius");
  cursor: text;
  transition: background-color 0.24s, box-shadow 0.24s;
  box-shadow: rgba(0, 0, 0, 0) 0 0 0 0;

  &:not(:last-of-type) {
    margin-right: size("gutter--mini");
  }

  &Selected {
    box-shadow: rgba(58, 151, 212, 0.36) 0 0 0 4px;
  }
}

