@use "sass:math";

@import "theme/ui-components/_variables";

:export {
  small: strip-unit(breakpoint("small"));
}

.productPromotion {
  background-size: cover;
  background-position: center;
  position: relative;
  text-align: center;
  cursor: pointer;
  user-select: none;
  overflow: hidden;
  display: block;
  border-radius: size("border-radius");

  @media (max-width: breakpoint("tiny")) {
    top: inherit;
    margin: size("gutter--mini") 0;
  }

  img {
    max-width: none;
    width: 100%;
    transition: transform transition("duration--templates") ease;
  }

  button {
    transition: background transition("duration--atoms") ease;
    margin-top: size("gutter--medium");
  }

  a {
    position: initial;

    &:hover:active {
      opacity: initial;
    }
  }

  @media (min-width: breakpoint("tiny")) {
    &:hover {
      img {
        transform: scale(1.01);
      }

      button {
        background: color("primary--hover");
      }
    }
  }
}

.text {
  color: #fff;
}

.size__small {
  font-size: 48px;

  @media (max-width: breakpoint("small")) {
    font-size: 24px;
  }
}

.size__large {
  font-size: 48px;
}

.image {
  object-fit: cover;

  @media (max-width: breakpoint("tiny")) {
    img {
      width: 100%;
      object-fit: cover;
      overflow: hidden;
    }
  }
}

.content {
  position: absolute;
  bottom: 50%;
  left: 50%;
  transform: translate(-50%, 50%);
  width: 60%;

  @media (max-width: breakpoint("small")) {
    position: absolute;
  }

  @media (max-width: breakpoint("tiny")) {
    flex-direction: inherit;
    margin: inherit;
    left: inherit;
    transform: inherit;
    width: inherit;
    bottom: 10px;
  }

  .textWrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 50px;
    color: #fff;

    p {
      text-align: left;
    }
  }

  .text {
    line-height: 1;
    font-weight: 800;
    margin: size("gutter") 0;
    color: #fff;
  }

  @media (max-width: breakpoint("tiny")) {
    top: size("gutter");

    button {
      display: none;
    }

    .text {
      background: color("primary");
      color: #fff;
      border-radius: 0 size("border-radius") size("border-radius") 0;
      font-size: size("font");
      font-weight: 700;
      padding: math.div(size("gutter"), 2) size("gutter");
    }
  }
}


.inline {
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: breakpoint("tiny")) {
    .content {
      position: inherit;
      transform: inherit;
      bottom: inherit;
      left: inherit;
      width: 90%;

      .textWrapper {
        height: inherit;
        color: color("primary");
      }

      .text {
        color: color("primary");

        @media (max-width: breakpoint("tiny")) {
          color: #fff;
        }
      }
    }
  }

  .content {
    left: 0;
  }

  .image {
    display: block;
  }

  .size__small {
    font-size: 24px;

    @media (max-width: breakpoint("tiny")) {
      font-size: size("font");
    }
  }
}
