@use "sass:math";

@import "ui-components/_variables";
@import "ui-components/_mixins";
@import "../../node_modules/@crossroads/shop-views/src/shop-views/SuccessView.scss";

.checkIconBG {
  margin-right: size("grid-gutter");
  background-color: transparent;

  svg {
    color: color("success");
    width: 48px;
    height: 48px;
  }
}
