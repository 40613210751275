@import "ui-components/_variables";
@import "ui-components/_mixins";

$animationTimingFunction: transition("ease-ease");
$animationDuration: transition("duration--templates");
$headerHeight: 68px;
$activeFiltersMinHeight: 70px;
$submitButtonHeight: 72px;
$closeButtonSideLength: 36px;
$closeButtonOffsetRight: -8px;


.block {
  border-top: 1px solid color("border--muted");
  width: 500px;
  background-color: #fff;
}
