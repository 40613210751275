@use "sass:math";

@import "theme/ui-components/_variables";
@import "theme/ui-components/_mixins";

$spacing: size("gutter--large") * 3;
$manufacturerLogoWidth: 180px;
$manufacturerLogoHeight: 108px;

.split {
  @media (min-width: breakpoint("small")) {
    @include stretch();
    @include clearfix();
  }

  @media (max-width: breakpoint("small") - 1px) {
    display: flex;
    flex-direction: column-reverse;
  }
}

.header {
  &__outer {
    margin-bottom: size("gutter");
    display: block;

    @media (min-width: breakpoint("small")) {
      display: none;
    }
  }

  &__inner {
    display: flex;
    justify-content: space-between;
    margin-bottom: size("gutter");
  }
}

.top {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-right: size("gutter--large") * 1.5;
}

.name {
  display: block;
  font-weight: bold;
  margin-bottom: size("gutter--mini");
  font-size: size("font--huge");
  margin-top: size("gutter--mini");
}

.brand {
  display: inline-block;
  color: color("primary");
  font-size: size(font--small);
}

.goto {
  text-decoration: underline;
}

.left {
  position: relative;

  @media (min-width: breakpoint("small")) {
    align-items: flex-start;
    padding: 0 math.div(size("grid-gutter"), 2);
    float: left;
    width: 50%;
    position: sticky;
    top: (size("header-height") + (math.div(size("header-margin"), 2)));
  }
}

.right {
  @media (min-width: breakpoint("small")) {
    padding: 0 math.div(size("grid-gutter"), 2);
    float: right;
    width: 50%;
    padding-left: $spacing;
  }
}

.qtyPicker,
.addToCart,
.login,
.options,
.options > * {
  @media (max-width: breakpoint("small") - 1px) {
    width: 100%;
  }
}

.addToCart {
  z-index: 2;
}

.qtyPicker {
  margin-bottom: size("gutter--large");

  @media (min-width: breakpoint("small")) {
    width: 135px;
  }
}

.priceWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.price {
  font-size: size(font--medium);
}

.msrp {
  font-size: size("font");
  margin-top: math.div(size("gutter"), 2);
  margin-bottom: 0;
  text-decoration: line-through;
}

.discount {
  color: #fff;
  background: color("success");
  text-align: center;
  user-select: none;
  top: size("gutter");
  left: size("gutter");
  padding: math.div(size("gutter"), 2) size("gutter");
  width: auto;
  display: inline-block;
  margin-bottom: size("gutter");

  span {
    font-weight: bold;
  }
}

.gutter {
  padding-top: size("gutter");
}

.mainPayment {
  font-size: size("font--medium");
  margin: 0;
}

.secondaryPayment {
  display: block;
  margin-top: 0;
  font-size: size("font--small");
  color: color("text--muted");

  @media (max-width: breakpoint("small") - 1px) {
    margin-bottom: size("gutter") !important;
  }
}

.addToCartButton,
.login {
  margin-bottom: size("gutter") !important;

  @media (max-width: breakpoint("tiny") - 1px) {
    width: 100%;
  }
}

.addToCartButton {
  width: auto;
  min-width: 50%;

  @media (max-width: breakpoint("small") - 1px) {
    width: 100%;
  }
}

.addToCart h3 {
  font-family: font("body");
  font-weight: bold;
  font-size: size("font");
  margin-bottom: size("gutter--mini");
}

.section {
  &Header {
    margin-top: size("gutter--large");
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    user-select: none;
    padding-bottom: size("gutter--small");
    border-bottom: 1px solid color("border--muted");

    h2 {
      font-size: size("font--medium");
      color: color("text");
      margin-top: 0;
      margin-bottom: 0;
      font-weight: bold;
    }

    svg {
      transition: transform transition("duration--organisms") transition("ease-ease");
      width: 36px;
      height: 36px;
      margin-right: size("gutter--small");

      .section__open & {
        transform: rotate(180deg);
      }
    }
  }

  &Body {
    padding: size("gutter") 0;
  }
}

.description {
  margin-top: size("gutter--small");
  color: color("text");

  ul {
    list-style-type: disc;
    list-style-position: inside;
    margin-bottom: size("gutter");
  }

  li + li {
    margin-top: size("gutter--small");
  }

  .manufacturer {
    margin-top: size("gutter--large") * 1.5;
  }
}

.logo {
  max-width: $manufacturerLogoWidth;
  max-height: $manufacturerLogoHeight;
  display: inline-block;
  font-size: size("font--large");
}

.lists {
  @media (min-width: breakpoint("small")) {
    @include stretch();
    @include clearfix();
  }
  padding-top: size("gutter--large") * 2;

  .relatedList {
    width: 100%;

    @media (min-width: breakpoint("small")) {
      padding: 0 math.div(size("grid-gutter"), 2);
      float: left;
      width: 50%;
    }
  }

  .historyList {
    @media (min-width: breakpoint("small")) {
      padding: 0 math.div(size("grid-gutter"), 2);
      float: right;
      width: 50%;
    }

    @media (max-width: breakpoint("small") - 1px) {
      margin-top: size("gutter--large");
    }
  }
}

.hintWrapper {
  min-height: 100vh;
}

@keyframes shine {
  0% {
    background-position: -100%;
  }

  100% {
    background-position: 100%;
  }
}

.dummyContent {
  .top {
    height: size("gutter--large");
  }

  .middle {
    height: size("gutter--large");
    margin-top: size("gutter--large");
  }

  .bottom {
    margin-top: size("gutter--large");
    height: 200px;
  }

  .top,
  .middle,
  .bottom {
    display: block;
    background-image: linear-gradient(90deg, color("disabled") 0%, color("background") 50%, color("disabled") 100%);
    background-size: 200%;
    animation: shine 1.5s infinite linear;
  }
}

.customCloseIcon {
  path {
    fill: color("text") !important;
  }
}

.productSection {
  margin: size("gutter--large") 0 size("gutter--large") * 2;

  p {
    color: color("text");
  }
}


:global {
  .ratingList:not(#foo) {
    font-size: size("font--mini");
    font-weight: bold;
    color: color("primary");
    display: flex;
    column-gap: size("gutter--small");
    margin: size("gutter--medium") 0;
    list-style: none;

    li {
      margin-top: 0;
    }

    @media (min-width: breakpoint("large")) {
      font-size: size("font--small");
      column-gap: size("gutter");
    }
  }

  .ratingScore {
    width: 23px;
    height: 23px;
    line-height: 20px;
    border-radius: 50%;
    border: solid 2px color("primary");
    text-align: center;
    display: inline-block;
    margin-left: size("gutter--tiny");

    @media (min-width: breakpoint("large")) {
      width: 26px;
      height: 26px;
      line-height: 22px;
    }
  }
}
