@use "sass:math";

@import "theme/ui-components/_variables";


.header {
  position: fixed;
  z-index: z-index("system");
  top: 0;
  left: 0;
  right: 0;
}

.placeholder {
  height: size("header-height");
}

