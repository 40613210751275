@import "theme/ui-components/_variables";

.cartCounter {
  svg {
    width: 34px;
    height: 34px;
  }
}

.count {
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 0.8rem;
  color: #fff;
  z-index: 1;
  right: -3px;
  top: 1px;
  width: 16px;
  height: 16px;
  background-color: color("primary");
  border-radius: 100%;
}
