@import "../../node_modules/@crossroads/ui-components/src/ui-components/Carousel";

.dot {
  border: none;

  &:not(.active) {
    padding: 10px;
  }

  &:not(.active)::after {
    content: "";
    display: block;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: color("border");
  }

  &.active {
    font-weight: bold;
    padding: 6px;
    background: color("primary");
  }

  @media (max-width: breakpoint("tiny")) {
    margin-right: 0.5em;
  }
}

.prev,
.next {
  color: color("border");
  padding: 0 size("gutter");
}

.prev {
  transform: rotate(180deg);
}

.next {
  transform: inherit;
}
