@import "../../node_modules/@crossroads/ui-components/src/ui-components/Dropdown";
@import "theme/ui-components/_variables";

$item-height: 42px;

.block {
  width: 100%;
  position: relative;
}

.head,
.body {
  border: 1px solid color("border--muted");
}

.headInner {
  border-right: 0;
}

.blockOpen .body {
  margin-top: 0;
  border-top: 0;
}

.chevronArea {
  width: $item-height;
  justify-content: center;
  padding: 0;
}

.headInner,
.item,
.chevronArea {
  display: flex;
  align-items: center;
  height: $item-height;
  padding-top: 0;
  padding-bottom: 0;
}

.item {
  height: 44px;
  color: color("text");
  border-bottom: 1px solid color("border--muted");

  &.active {
    background-color: inherit;

    &::after {
      content: "";
      position: absolute;
      right: size("gutter--mini");
      width: 20px;
      height: 20px;
      background: url("/icons/tick.svg") no-repeat center;
      background-size: 100%;
    }
  }

  &:hover {
    text-decoration: underline;
    background: color("body--off");
  }
}

.hasVariant {
  &.countryDropdown {
    &.block {
      height: 1rem + (size("input-padding") * 2);
    }

    .head {
      height: 100%;

      &Inner {
        height: 100%;
        padding-top: 1.2rem;
        padding-left: 1rem;
        padding-right: 1rem;
        font-size: size("font");

        &:focus {
          border-color: transparent;
          outline: none;
        }
      }
    }

    .body {
      border-radius: size("border-radius");
      max-height: 300px;
      overflow-y: auto;
      border: 1px solid color("border--muted");
      font-size: size("font--small");
    }

    &.blockDown .body {
      box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.25);
    }

    &.blockUp .body {
      box-shadow: 0 -5px 5px 0 rgba(0, 0, 0, 0.25);
    }

    .item {
      background-color: #fff;
    }
  }

  &.dropdownSort {
    &.block {
      width: 150px;
      border: none;
    }

    .head {
      border: none;
      height: 23px;
    }

    .chevronArea {
      width: 23px;
      height: auto;
    }

    .item {
      font-size: size("font--small");

      &.active {
        &::after {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
}
