@import "../../node_modules/@crossroads/shop-views/src/shop-views/DiscountCode";
@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

$buttonHeight: 3rem;

.boxHeader {
  color: color("text");
  font-weight: 600;
  padding: size("gutter") size("gutter") size("grid-gutter");

  & + main {
    border-top: none !important;
  }
}

.boxBody {
  padding: size("gutter--small") size("gutter") size("gutter");
}

.discountCode {
  input {
    height: $buttonHeight;
    line-height: $buttonHeight;
  }

  button {
    height: $buttonHeight !important;
    width: 40% !important;
  }
}
