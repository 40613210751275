@use "sass:math";

@import "theme/ui-components/_variables";

$headerHeight: size("header-height");
$miniCartMaxHeight: 495px;
$miniCartWidth: 312px;
$miniHamburgerWidth: 25px;

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 0.3;
  }
}

@keyframes slideDown {
  0% {
    opacity: 0.7;
    transform: translateY(0);
  }

  100% {
    opacity: 1;
    transform: translateY($headerHeight);
  }
}

@keyframes fadeInSearch {
  0% {
    opacity: 0.7;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeInBackButton {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.headerNav {
  z-index: z-index("system") + 2;
  background: color("primary");
  height: size("header-height");
  width: 100%;
  display: flex;
  transition: top transition("duration--organisms") transition("ease-ease");
  will-change: top;

  &.active {
    box-shadow: -1px 3px 15px 0 rgb(141 151 166 / 0.16);
  }

  .hidden & {
    top: -size("header-height");
  }
}

.item__logo {
  padding: 0 !important;
  margin-right: size("gutter");

  @media (min-width: breakpoint("medium")) {
    margin-right: size("gutter--large");
  }

  &:hover {
    text-decoration: none;
  }
}

.wrapper {
  flex: 1 0 100%;
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: stretch;
}

.left {
  display: flex;
  align-items: center;
  flex: 1 0 auto;

  @media (max-width: (breakpoint("medium") - 1px)) {
    flex: 1 0 auto;
  }
}

.nav {
  display: flex;
  align-items: stretch;

  svg {
    width: 36px;
    height: 36px;
    transition: transition("duration--atoms") transition("ease-in-out");
  }
}

.right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1 0 auto;
}

.item__search {
  display: flex !important;
  flex: 1 0 auto;
  align-items: center;

  &:hover svg {
    transform: scale(1.1);
  }
}

.item__customerService {
  &:hover svg {
    transform: scale(1.1);
  }
}

.search + .left {
  order: -1;
}

.mobile {
  &.search + .left + .right {
    display: none;
  }

  &.search + .left {
    display: none;
  }

  @media (min-width: breakpoint("medium")) {
    display: none !important;
  }
}

.links {
  display: flex;
}

.item {
  flex: 0 0 auto;
  color: color("text--inverted");
  text-decoration: none;
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  height: size("header-height");
  padding: 0 0.8rem;
  transition: border-color transition("duration--atoms") transition("ease-out");

  &.active {
    font-weight: bold;
  }

  & > span {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      bottom: -6px;
      display: block;
      height: 2px;
      width: 100%;
      background: transparent;
      transition: background-color transition("duration--atoms") transition("ease-in-out");
    }
  }

  &:hover:not(.item__logo) {
    text-decoration: none;

    & > span {
      &::after {
        background: color("text--inverted");
      }
    }
  }

  &__noMargin {
    margin: 0 !important;
  }

  &__cartCounter {
    transition: transform transition("duration--organisms") transition("ease-ease");
    will-change: transform;

    div {
      position: relative;
    }

    &:hover {
      transform: scale(1.1);
    }

    @media (max-width: (breakpoint("medium") - 1px)) {
      margin-right: size("gutter");
    }
  }

  &__allproducts,
  &__categories,
  &__brands,
  &__customerService,
  &__search,
  &__points {
    @media (max-width: (breakpoint("medium") - 1px)) {
      display: none !important;
    }
  }
}

.back {
  animation: fadeInBackButton transition("duration--templates") transition("ease-out") forwards;
  font-weight: 700;
}

.categories,
.brands {
  @media (min-width: breakpoint("medium")) {
    background: #fff;
    position: fixed;
    left: 0;
    right: 0;
    z-index: z-index("system") + 1;
    animation: slideDown transition("duration--organisms") transition("ease-out") forwards;
  }
}

.dim {
  position: fixed;
  top: size("header-height");
  bottom: 0;
  left: 0;
  right: 0;
  z-index: z-index("system");
  background: color("text");
  animation: fadeIn transition("duration--organisms") transition("ease-out") forwards;
}

.miniCart {
  position: absolute;
  top: size("header-height");
  right: size("gutter--large");
  left: auto;
  max-height: $miniCartMaxHeight;
  width: $miniCartWidth;
  z-index: z-index("overlay");
  box-shadow: 0 24px 64px 0 rgb(0 0 0 / 0.1);
  border-radius: size("border-radius");

  @media (max-width: breakpoint("small")) {
    right: size("gutter");
  }

  @media (max-width: breakpoint("tiny")) {
    right: size("gutter");
    left: size("gutter");
    width: auto;
  }
}

.accountBadge {
  margin-right: math.div(size("gutter--mini"), 2);
}

.hamburger {
  min-width: $miniHamburgerWidth;
  margin: 0 -0.8rem;

  @media (min-width: breakpoint("medium")) {
    display: none;
  }
}

.desktopNavHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: size("header-height");
  border-bottom: 1px solid color("border--muted");

  h3 {
    font-size: size("h1");
    margin-bottom: 0;
  }
}

.subNav {
  background: color("OOC--color");
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  min-height: 100vh;

  @media (max-width: (breakpoint("medium") - 1px)) {
    min-height: 100vh;
  }
  display: none;

  @media (max-width: breakpoint("medium")) {
    left: 100%;
  }
}

.desktop {
  @media (max-width: (breakpoint("medium") - 1px)) {
    display: none !important;
  }
}

:not(.mobile).subNav.search {
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  min-height: 0;
  background: none;
  position: relative;
  top: auto;
  right: auto;
  bottom: auto;
  left: auto;
}

.mode__categories .categories {
  display: block;
}

.mode__brands .brands {
  display: block;
}

.mode__search .search {
  display: block;
}

.mode__language .language {
  display: block;
}
