@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

.col__subscribeToNewsletter {
  p {
    color: #ddd;
  }

  .newsletterInput {
    width: 100%;
    margin-top: size("gutter--large") * 1.5;
  }

  input {
    color: color("text") !important;
  }

  .formButton {
    margin-top: size("gutter--large");
    padding: size("gutter--small") size("gutter");
    background: color("primary--light");
    color: color("primary");
    font-weight: bold;
  }
}

.col__header {
  font-size: size("font--large");

  @media (max-width: (breakpoint("tiny") - 1px)) {
    font-size: size("font--medium");
  }
}
