@use "sass:math";

@import "../../node_modules/@crossroads/ui-components/src/ui-components/ActiveFilters";
@import "theme/ui-components/_variables";

$pill-height: 31px;


.activeFilters {
  padding: 0 size("gutter--large");
}

.activeFiltersLeft {
  margin-left: -#{size("gutter--mini")};
  padding: size("gutter--mini") 0;
}

.totalCount {
  display: none;
}

.clearbutton {
  &Desktop {

    @media (min-width: breakpoint("tiny")) {
      position: absolute;
      right: 0;
      top: -46px;
    }
  }
}

.activeFilter {
  margin: size("gutter--large") 0 math.div(size("gutter--mini"), 2) 0;
  margin-left: size("gutter--mini");
  flex-direction: row-reverse;
  align-items: center;
  border: 2px solid color("primary");
  background: #fff;
  border-radius: $pill-height;
  height: $pill-height;
  padding: 0 size("gutter--mini");
  padding-right: math.div(size("gutter--mini"), 1.5);

  &:hover {
    text-decoration: underline;
  }

  .text {
    margin-left: 0;
    margin-right: 8px;
    font-size: size("font--mini");
  }

  .icon {
    border-radius: 50%;
    background: color("text");
  }
}
