@import "ui-components/_variables";
@import "ui-components/_mixins";

$checkmarkMarginRight: 0.3rem;

.value {
  background-color: color("background");
  padding: size("gutter") size("gutter--large");
  border-top: 1px solid color("border--muted");
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .checkmark {
    fill: color("primary");
    stroke: color("primary");
    position: absolute;
    right: calc(#{size("gutter--large")});
  }

  &:hover {
    background-color: color("primary") !important;
    color: #fff !important;

    .checkmark {
      fill: #fff;
      stroke: #fff;
    }
  }

  &:first-child {
    border-top: none;
  }
}
