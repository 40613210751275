@import "theme/ui-components/_variables";

$delimeterMargin: 0.4rem;

.breadcrumbs {
  font-size: size("font");
  margin-top: size("gutter");
  margin-bottom: size("gutter");
}

.delimeter {
  display: inline-block;
  color: color("text--muted");
  margin-left: $delimeterMargin;
  margin-right: $delimeterMargin;
}
