@import "../../../node_modules/@crossroads/ui-components/src/ui-components/FilterMenu/FilterContainer";
@import "theme/ui-components/_variables";

$headerHeight: 60px;

.block {
  border-bottom: 1px solid color("border--muted");

  &__header {
    & > h3 {
      font-size: size("font");
      color: color("text");
      text-transform: none;
    }

    .closeIcon {
      transform: rotate(90deg);
      transition: transform transition("duration--organisms") transition("ease-ease");

      .open & {
        transform: rotate(-90deg);
      }
    }
  }

  > div {
    position: relative;
  }
}

