@use "sass:math";

@import "ui-components/_variables";

$size: 16px;
$color: color("primary");

.block {
  position: relative;
  user-select: none;
}

.input {
  position: absolute;
  width: $size;
  height: $size;
  opacity: 0;
}

.radio {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.button {
  display: inline-block;
  width: $size;
  height: $size;
  background: #fff;
  border-radius: 50%;
  border: 1px solid $color;
  margin-right: math.div(size("gutter--mini"), 1.5);

  .radioChecked & {
    &::after {
      content: "";
      position: relative;
      display: block;
      width: round(math.div($size, 2));
      height: round(math.div($size, 2));
      top: round(math.div($size, 5.7));
      left: round(math.div($size, 5.7));
      background: $color;
      border-radius: 50%;
    }
  }

  input:active + & {
    border-color: lighten($color, 20);
  }

  [data-whatintent="keyboard"] input:focus + & {
    box-shadow: 0 0 0 0.1em #79a;
  }
}
