/*
  fonts
  ------------------------------------------------------------

  Custom font faces goes here.

  Format them like this:

  @font-face {
    font-family: "Name";
    font-weight: 400;
    font-style: normal;
    src: url("../../static/assets/fonts/fontname.woff")  format("woff"),
         url("../../static/assets/fonts/fontname.woff2") format("woff2"),
         url("../../static/assets/fonts/fontname.ttf")   format("truetype");
  }

  Use the same family name but different font weights for
  different weights of the same family. In other words; DON'T
  name font faces with another weight as "name-regular",
  "name-bold" or similar. Just use the same name but a
    different weight.
*/

@import url("https://p.typekit.net/p.css?s=1&k=nef0tqg&ht=tk&f=26979.26980.26983.26984&a=86600906&app=typekit&e=css");

@font-face {
  font-family: "objektiv-mk2";
  src:
    url("https://use.typekit.net/af/84659b/00000000000000007735b573/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff2"),
    url("https://use.typekit.net/af/84659b/00000000000000007735b573/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff"),
    url("https://use.typekit.net/af/84659b/00000000000000007735b573/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 400;
  font-stretch: normal;
}

@font-face {
  font-family: "objektiv-mk2";
  src:
    url("https://use.typekit.net/af/986a34/00000000000000007735b576/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),
    url("https://use.typekit.net/af/986a34/00000000000000007735b576/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),
    url("https://use.typekit.net/af/986a34/00000000000000007735b576/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}

@font-face {
  font-family: "objektiv-mk2";
  src:
    url("https://use.typekit.net/af/069915/00000000000000007735b581/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),
    url("https://use.typekit.net/af/069915/00000000000000007735b581/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),
    url("https://use.typekit.net/af/069915/00000000000000007735b581/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
}

@font-face {
  font-family: "objektiv-mk2";
  src:
    url("https://use.typekit.net/af/fec100/00000000000000007735b582/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff2"),
    url("https://use.typekit.net/af/fec100/00000000000000007735b582/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff"),
    url("https://use.typekit.net/af/fec100/00000000000000007735b582/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 700;
  font-stretch: normal;
}

.tk-objektiv-mk2 {
  font-family: "objektiv-mk2", sans-serif;
}
