@import "ui-components/_variables";
@import "ui-components/_mixins";

$headerHeight: size("header-height");
$submitButtonHeight: 72px;
$closeButtonSideLength: 36px;
$closeButtonOffsetRight: -8px;

.header {
  flex: 0 0 auto;
  height: $headerHeight;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: size("gutter") size("gutter--large");

  & > h2 {
    margin: 0;
    font-size: size("font--medium");
    color: color("text");
  }

  .closeButton {
    width: $closeButtonSideLength;
    height: $closeButtonSideLength;
    right: $closeButtonOffsetRight;

    & > svg {
      color: color("secondary");
      width: 100%;
      height: 100%;
    }
  }
}

.activeFilters {
  border-top: 1px solid color("border--muted");
  border-bottom: 1px solid color("border--muted");
}

.body {
  height: calc(100vh - #{$headerHeight});
  max-height: calc(100vh - #{$headerHeight});
  min-height: 0;

  .top {
    height: calc(100% - #{$submitButtonHeight});
    max-height: calc(100% - #{$submitButtonHeight});
    overflow-y: auto;
  }

  .bottom {
    height: $submitButtonHeight;

    button.submit_button {
      width: 100%;
      height: 100%;
      border-radius: 0;
    }
  }
}

.filters {
  flex: 1;
  overflow-y: auto;
  overscroll-behavior: contain;
}

.filterMenu {
  border-top: none;
  width: 100%;
}

.value {
  padding: size("gutter") size("gutter--large");
  border-top: 1px solid color("border--muted");
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  user-select: none;

  &:hover {
    background-color: #eee !important;
  }

  &:first-child {
    border-top: none;
  }

  &.active {
    background-color: color("primary") !important;
    color: #fff !important;
  }
}
