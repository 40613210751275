@import "theme/ui-components/_variables";
@import "theme/ui-components/_mixins";

:export {
  animationDuration: strip-unit(transition("duration--organisms"));
}

$height: size("header-height");

.hamburger {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: z-index("overlay");
  transition: transition("duration--organisms") transition("ease-in-out");
  transform: translateX(100%);
  opacity: 0;

  &.open {
    transform: translateX(0%);
    opacity: 1;
  }

  &.open.subNavOpen {
    transform: translateX(-100%);
  }

  @media (min-width: breakpoint("medium")) {
    display: none !important;
  }
}

.hamburgerMenu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: color("OOC--color");
  height: 100vh;
  overflow-y: auto;
}

.topMenu {
  width: 100%;
  flex: 1 0 auto;
}

.bottomMenu {
  width: 100%;
  flex: 1 0 auto;
  color: #fff;
  padding-top: size("gutter--large") * 1.5;
  padding-bottom: size("gutter--large") * 1.5;

  .row {
    display: flex;
    align-content: center;
    justify-content: space-between;

    &:not(:last-child) {
      padding-bottom: size("gutter--large");
    }

    & > div:last-child {
      text-align: right;
    }
  }

  .link {
    cursor: pointer;
    color: #fff;

    &:hover {
      text-decoration: underline;
    }
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: size("header-height");
  min-height: size("header-height");
  width: 100%;
  border-bottom: 1px solid color("border");
  font-weight: bold;
  font-size: size("font--medium");
  background: color("primary");

  span {
    color: #fff;
  }

  svg {
    color: #fff;
  }
}

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: size("font--small");
  font-weight: bold;
  border-bottom: 1px solid color("border");
  padding: 0 size("gutter--small");
  height: size("mobile-menu-row-height");
  color: color("text");
  cursor: pointer;
  user-select: none;

  &:hover {
    text-decoration: none;
  }

  span {
    display: flex;
    align-items: center;
  }

  svg + span {
    margin-left: size("gutter--mini");
  }

  svg {
    color: color("text");
    width: 36px;
    height: 36px;
  }

  &.active svg {
    color: color("primary");
  }
}

.chevron {
  width: 36px;
  height: 36px;
  transform: rotate(-90deg);
}

.close {
  width: 36px;
  height: 36px;
}

.linkDisabled {
  pointer-events: none;
}
