@import "ui-components/_variables";
@import "ui-components/_mixins";

:export {
  mini: strip-unit(breakpoint("mini"));
  small: strip-unit(breakpoint("small"));
  medium: strip-unit(breakpoint("medium"));
  large: strip-unit(breakpoint("large"));
}

picture {
  display: block;
}

.image {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
}
