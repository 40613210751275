@use "sass:math";

@import "../../node_modules/@crossroads/ui-components/src/ui-components/ProductViewMedia";
@import "ui-components/_variables";
@import "ui-components/_mixins";

$item-width: 76px;
$image-max-width: 800px; // The product image size on the server is 800x80

.carousel {
  *:not(picture):not(img) {
    position: relative;
  }

  &Controls {
    height: 48px;
  }
}

.gallery {
  @media (min-width: breakpoint("small")) {
    display: flex;
    align-items: center;
    flex-direction: column;

    .block__bottom &,
    .block__top & {
      margin-left: -#{math.div(size("grid-gutter"), 2)};
      margin-right: -#{math.div(size("grid-gutter"), 2)};
      flex-flow: row wrap;
    }

    .block__left &,
    .block__right & {
      margin-top: -#{math.div(size("grid-gutter"), 2)};
      margin-bottom: -#{math.div(size("grid-gutter"), 2)};
      width: auto;
      flex: 0 0 auto;
    }
  }

  &Item {
    max-width: calc(#{$item-width} + #{size("grid-gutter")});
    padding-left: 0 !important;

    img {
      border: 1px solid color("border--muted");
      border-radius: size("border-radius");
      padding: math.div(size("grid-gutter"), 2) !important;
    }
  }
}

.image {
  position: relative;
  border: 1px solid color("border--muted");
  border-radius: size("border-radius");
}

.lightboxImageWrapper {
  position: relative;
}

.lightbox {
  &Image {
    max-width: $image-max-width;
  }

  &Gallery {
    &Item {
      width: $item-width;
      height: $item-width;
    }
  }

  &Close {
    width: $item-width;
    height: $item-width;
  }

  .galleryItem {
    width: $item-width;
  }
}
