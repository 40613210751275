@import "ui-components/_variables";
@import "ui-components/_mixins";

:export {
  headerHeight: strip-unit(size("header-height"));
}

.block {
  z-index: z-index("system");
}
