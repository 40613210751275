@import "theme/ui-components/_variables";
@import "theme/ui-components/_mixins";

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: size("gutter--large") * 2;
  padding-bottom: size("gutter--large") * 2;
}
